/* 
 * Vendor Libs 
 */
import $ from 'jquery';

/* Foundation */
import { Foundation } from 'foundation-sites/js/foundation.core';
// Responsive Menu
import { ResponsiveMenu } from 'foundation-sites/js/foundation.responsiveMenu';
import { Triggers } from 'foundation-sites/js/foundation.util.triggers';
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
import { Motion } from 'foundation-sites/js/foundation.util.motion';
import { ResponsiveToggle } from 'foundation-sites/js/foundation.responsiveToggle';
// Tabs
import { Tabs } from 'foundation-sites/js/foundation.tabs';
// Accordion
import { Accordion } from 'foundation-sites/js/foundation.accordion';

Foundation.addToJquery($);

Foundation.plugin(ResponsiveMenu, 'ResponsiveMenu');
Foundation.plugin(ResponsiveToggle, 'ResponsiveToggle');
Foundation.plugin(Tabs, 'Tabs');
Foundation.plugin(Accordion, 'Accordion');

// Styles
require('../styles/vendor.scss');